<template>
    <div class="Game-Slot">
                <MenuBar></MenuBar>
                <div class="box-img">
        <!--            <div class="box-user-img"><img src="@/assets/images/icon/set-icon/icon-acc-manage.png"/></div>-->
                    <div class="box-user-detail">

                        <strong>ตู้ Slot</strong>
<!--                        <small>Slot Machine</small>-->
                    </div>
                </div>
        <div class="bg-overlay">
            <div class="full-width" id="main">

                <div class="container-fluid-hilo-game">

                    <div class="wrap-content-games">

                        <div class="wrapper-box-games wrapper-box-games-slots">
                            <div class="close-game"></div>
                            <div class="container-box-games-slots">
                                <div class="mainbox-games-slots">
                                    <div class="mainbox-games-slots-info">
                                        <div class="content-top-score">
                                            <div class="box-bonus-win">
                                                <small>Bonus-Win</small>
                                                <span>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>1</li>
                                <li>8</li>
                                <li>2</li>
                            </ul>
                        </span>
                                                <span class="bg">
                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                        </span>
                                            </div>
                                            <div class="box-credit">
                                                <small>Credit</small>
                                                <span>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                        </span>
                                                <span class="bg">
                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                        </span>
                                            </div>
                                        </div>
                                        <!--<button>Click to fade in boxes with a delay</button>-->
                                        <div class="main-slots">
                                            <div class="box-main-slots">

                                                <div class="run-ac run-box" id="run"
                                                     style="display: none; opacity: 0.8;"></div>
                                                <div class="run-ac run-box" id="run1"
                                                     style="display:none; opacity: 0.7;"></div>
                                                <div class="run-ac run-box" id="run2"
                                                     style="display:none; opacity: 0.6;"></div>
                                                <div class="run-ac run-box" id="run3"
                                                     style="display:none;opacity: 0.5;"></div>
                                                <div class="run-ac run-box" id="run4"
                                                     style="display:none;opacity: 0.4;"></div>

                                                <div class="ico-action active-point run-point" id="runpoint"
                                                     style="display: none; top: 42px; left: 35px;"></div>

                                                <div class="middnum">
                                                    <span class="left middnum-8 counter-n"></span>
                                                    <span class="right middnum-2 counter-n"></span>
                                                    <span class="bg-num"></span>
                                                    <span class="bg-num"></span>
                                                </div>
                                                <!--<div class="area-top box-2 active">-->
                                                <!--<div class="ico-action active-point "></div>-->
                                                <!--<div class="active-run blink"></div>-->
                                                <!--<div class="ico-slot sl-bell"></div>-->
                                                <!--</div>-->
                                                <!-- TOP //////////////////-->
                                                <div class="top-row-slot">
                                                    <div class="area-top box-1">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-orange"></div>
                                                    </div>
                                                    <div class="area-top box-2">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-bell"></div>
                                                    </div>
                                                    <div class="area-top box-3">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-50bar"></div>
                                                    </div>
                                                    <div class="area-top box-4">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-100bar"></div>
                                                    </div>
                                                    <div class="area-top box-5">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-apple"></div>
                                                    </div>
                                                    <div class="area-top box-6">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                    <div class="area-top box-7">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-watermelon"></div>
                                                    </div>
                                                </div>


                                                <!-- Right //////////////////-->

                                                <div class="right-row-slot">
                                                    <div class="area-right box-8">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-grape"></div>
                                                    </div>
                                                    <div class="area-right box-9">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                    <div class="area-right box-10">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-onemore"></div>
                                                    </div>
                                                    <div class="area-right box-11">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-apple"></div>
                                                    </div>
                                                    <div class="area-right box-12">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                </div>

                                                <!-- Bottom /////////////////-->
                                                <div class="bottom-row-slot">
                                                    <div class="area-bottom box-19">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-grape"></div>
                                                    </div>
                                                    <div class="area-bottom box-18">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                    <div class="area-bottom box-17">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-apple"></div>
                                                    </div>
                                                    <div class="area-bottom box-16">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-77icon"></div>
                                                    </div>
                                                    <div class="area-bottom box-15">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                    <div class="area-bottom box-14">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-bell"></div>
                                                    </div>
                                                    <div class="area-bottom box-13">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-orange"></div>
                                                    </div>
                                                </div>

                                                <!-- Left //////////////////-->
                                                <div class="left-row-slot">
                                                    <div class="area-left box-20">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-star"></div>
                                                    </div>
                                                    <div class="area-left box-21">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                    <div class="area-left box-22">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-onemore2"></div>
                                                    </div>
                                                    <div class="area-left box-23">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-apple"></div>
                                                    </div>
                                                    <div class="area-left box-24">
                                                        <div class="ico-action"></div>
                                                        <div class="ico-slot bx-cherry"></div>
                                                    </div>
                                                </div>
                                                <!--                        <div class="box-middle-image">-->
                                                <!--                            <img src="/m/images/slot/image-slot.jpg">-->
                                                <!--                        </div>-->
                                            </div>
                                        </div>
                                        <div class="content-panel-coin">
                                            <div class="panel-coin">
                                                <div class="box-balance-coin">
                                                    <div class="box-bonus-win">
                                                        <small>Balance</small>
                                                        <span>
                                                            <ul>
                                                                <li></li>
                                                                <li></li>
                                                                <li></li>
                                                                <li></li>
                                                                <li></li>
                                                                <li>1</li>
                                                                <li>8</li>
                                                                <li>2</li>
                                                            </ul>
                                                        </span>
                                                        <span class="bg">
                                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!--                                                <div class="box-coin">-->
                                                <!--                                                    <div class="coins-100"><span>100</span></div>-->
                                                <!--                                                    <div class="coins-10 active-coin"><span>10</span></div>-->
                                                <!--                                                    <div class="coins-1"><span>1</span></div>-->
                                                <!--                                                </div>-->
                                                <div class="box-panel-play-tool">
                                                    <div class="box-coin">
                                                        <div class="GameChipForSelect b-coin c-5 active"></div>
                                                        <div class="GameChipForSelect b-coin c-10 "></div>
                                                        <div class="GameChipForSelect b-coin c-50 "></div>
                                                        <div class="GameChipForSelect b-coin c-100 "></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="bottom-panel">
                                    <div class="bottom-panel-info">
                                        <div class="btnClear"></div>
                                        <div class="btnNavi">
                                            <div class="btnNavi-left"></div>
                                            <div class="btnNavi-right"></div>
                                        </div>
                                        <div class="btnHilo">
                                            <div class="btnHilo-hi"></div>
                                            <div class="btnHilo-lo"></div>
                                        </div>
                                        <div class="btnGo active click-run"></div>
                                    </div>
                                </div>
                                <div class="content-panel-score">
                                    <div class="bottom-panel-score">
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action"></div>
                                            <div class="top-num-score-1 red-score-1">100</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-bar"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action"></div>
                                            <div class="top-num-score-1">40</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-77"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action"></div>
                                            <div class="box-balance-coin">
                                                <div class="top-num-score-1">30</div>
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-star"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action"></div>
                                            <div class="top-num-score-1">20</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-watermelon"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action"></div>
                                            <div class="top-num-score-1">20</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-bell"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action"></div>
                                            <div class="top-num-score-1">15</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-grape"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="ico-action active-point"></div>
                                            <div class="top-num-score-1">10</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li>1</li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-orange"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="top-num-score-1 red-score-1">2</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li>1</li>
                                <li>0</li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-cherry"></div>
                                        </div>
                                        <div class="bottom-panel-score-1">
                                            <div class="top-num-score-1 red-score-1">5</div>
                                            <div class="box-balance-coin">
                                                <div class="box-bonus-win">
                                        <span>
                                            <ul>
                                <li></li>
                                <li></li>
                                <li>0</li>
                            </ul>
                                        </span>
                                                    <span class="bg">
                                            <ul>
                                <li>8</li>
                                <li>8</li>
                                <li>8</li>
                            </ul>
                                        </span>
                                                </div>
                                            </div>
                                            <div class="btn-slot btn-apple"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>


            </div>
        </div>
    </div>

</template>


<script>
    import MenuBar from '@/components/MenuBar'
    export default {
        name: "slidefruit",
        components: {
            MenuBar,
        },
        methods: {},
        mounted() {
            const $ = this.$jquery

            $(".click-run").click(function () {
                var audio = new Audio('/images/fruit-slot/sound/C122.ogg');
                audio.play();
                $("#runpoint").delay(100).fadeIn();
                $("#run").delay(100).fadeIn();
                $("#run1").delay(175).fadeIn();
                $("#run2").delay(240).fadeIn();
                $("#run3").delay(310).fadeIn();
                $("#run4").delay(380).fadeIn();
                setTimeout(() => {
                    // $("#run").fadeIn();
                    $("#run").removeClass("run-1");
                    $("#run1").removeClass("run-1");
                    $("#run2").removeClass("run-1");
                    $("#run3").removeClass("run-1");
                    $("#run4").removeClass("run-1");
                    $("#runpoint").removeClass("run-point");
                    $("#run").addClass("blink");
                    $("#runpoint").addClass("blink");
                    document.getElementById("run1").style.display = "none";
                    document.getElementById("run2").style.display = "none";
                    document.getElementById("run3").style.display = "none";
                    document.getElementById("run4").style.display = "none";
                    var audio = new Audio('/images/fruit-slot/sound/C123.ogg');
                    audio.play();
                }, 3000);

                setTimeout(() => {
                    document.getElementById("run").style.display = "none";
                    document.getElementById("runpoint").style.display = "none";
                }, 7000);
            });
        },
    };
</script>
<style src="@/assets/css/slots-game.css" scoped></style>
<style scoped>

    @font-face {
        font-family: 'ds-digitalnormal';
        src: url("../assets/fonts/ds_digital/ds-digit-webfont.eot");
        src: url("../assets/fonts/ds_digital/ds-digit-webfont.svg");
        src: url("../assets/fonts/ds_digital/ds-digit-webfont.woff");
        src: url("../assets/fonts/ds_digital/ds-digit-webfont.woff2");
    }

    /*.bg-overlay {*/
    /*    position: fixed;*/
    /*    width: 100%;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*    height: 100%;*/
    /*    z-index: 999;*/
    /*    !*background-color: #fff;*!*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/


</style>
<style>
    .box-img {
        z-index: 1;
    }
</style>